import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import SectionsTable from './SectionsTable';
import SearchPage from './SearchPage';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import TopBar from './TopBar';
import AnimatedBanner from './AnimatedBanner';
import Footer from './Footer';
import './App.css';

function App() {
    const [user, setUser] = useState(null);
    const navigate = useNavigate(); // Initialize the navigate function

    const handleLogin = (loggedInUser) => {
        setUser(loggedInUser);
        navigate('/search'); // Redirect to search page after login
    };

    const handleLogout = () => {
        setUser(null);
        navigate('/login'); // Redirect to login page after logout
    };

    const showTopBar = user !== null;

    return (
        <div className="App">
            {showTopBar && <TopBar user={user} />}
            {user && <AnimatedBanner />}
            
            <nav>
    {user ? (
        <>
            <button onClick={() => navigate('/sections')}>قاعدة البيانات</button>
            <button onClick={() => navigate('/search')}>البحث</button>
            <button onClick={handleLogout}>خروج</button>
            
        </>
    ) : (
        <div className="button-container">
            <button onClick={() => navigate('/login')}>Login</button>
            <button onClick={() => navigate('/register')}>Register</button>
        </div>
    )}
</nav>

            

            <Routes>
                <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/sections" element={user ? <SectionsTable /> : <Navigate to="/login" />} />
                <Route path="/search" element={user ? <SearchPage user={user} /> : <Navigate to="/login" />} />
                <Route path="*" element={<Navigate to={user ? "/search" : "/login"} />} />
            </Routes>

            <Footer />
        </div>
    );
}

export default App;
