import React, { useState } from 'react';
import axios from 'axios';
import './SectionsTable.css';

function SectionsTable() {
    const [selectedCollection, setSelectedCollection] = useState('');
    const [sections, setSections] = useState([]);
    const [message, setMessage] = useState('');
    const [maxPersons, setMaxPersons] = useState(0);
    const [maxCompanys, setMaxCompanys] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of items per page

    // Define the mapping of collection keys to their labels
    const collectionLabels = {
        decisions: 'القرارات',
        laws: 'القوانين',
        Decrees: 'المراسيم',
        LegislativeDecrees: 'المراسيم التشريعية',
        radios: 'الاذاعات',
        newsletters: 'النشرات',
        advertisements: 'الاعلانات',
        OfficialAnnouncements: 'الاعلانات الرسمية',
        judicialDecisions: 'الاحكام القضائية',
        assemblyMemoirs: 'مذكرات مجلس الشعب',
        Circulars: 'التعاميم',
    };

    const fetchSections = async (collectionName) => {
        try {
            const res = await axios.get(`https://node1.syriaonrecord.com/sections/${collectionName}`);
            console.log('Fetched sections:', res.data);
            setSections(res.data);
            setMessage('');
            determineMaxPersons(res.data);
            determineMaxCompanys(res.data);
            setCurrentPage(1); // Reset to the first page when fetching new data
        } catch (error) {
            console.error('Error fetching sections:', error);
            setMessage(error.response && error.response.status === 404 ? 'Collection not found.' : 'Error fetching sections.');
            setSections([]);
        }
    };

    const determineMaxPersons = (sections) => {
        let max = 0;
        sections.forEach((section) => {
            if (section.namePersons && section.namePersons.length > max) {
                max = section.namePersons.length;
            }
        });
        setMaxPersons(max);
    };

    const determineMaxCompanys = (sections) => {
        let max = 0;
        sections.forEach((section) => {
            if (section.nameCompanys && section.nameCompanys.length > max) {
                max = section.nameCompanys.length;
            }
        });
        setMaxCompanys(max);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
    };

    // Calculate the current items to display based on the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentItems = sections.slice(startIndex, startIndex + itemsPerPage);

    // Pagination controls
    const totalPages = Math.ceil(sections.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    return (
        <div className="SectionsTable">
            <h1>قاعدة البيانات</h1>
            <select
                value={selectedCollection}
                onChange={(e) => {
                    setSelectedCollection(e.target.value);
                    fetchSections(e.target.value);
                }}
            >
                <option value="">اختر النوع</option>
                {Object.keys(collectionLabels).map((key) => (
                    <option key={key} value={key}>
                        {collectionLabels[key]}
                    </option>
                ))}
            </select>
            <p>{message}</p>
            {currentItems.length > 0 ? (
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>اسم القرار</th>
                                <th>وصف القرار</th>
                                {[...Array(maxPersons)].map((_, index) => (
                                    <th key={index}>الشخص{index + 1}</th>
                                ))}
                                {[...Array(maxPersons)].map((_, index) => (
                                    <th key={index}>الأب{index + 1}</th>
                                ))}
                                {[...Array(maxPersons)].map((_, index) => (
                                    <th key={index}>الأم{index + 1}</th>
                                ))}
                                {[...Array(maxCompanys)].map((_, index) => (
                                    <th key={index}>الشركة{index + 1}</th>
                                ))}
                                <th>القسم</th>
                                <th>التاريخ</th>
                                <th>الوزارة</th>
                                <th>المؤسسة المعنية</th>
                                <th>نص القرار</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((section, index) => (
                                <tr key={index}>
                                    <td>{section.name}</td>
                                     <td><div className="scrollable-text">{section.description}</div></td>
                                    {[...Array(maxPersons)].map((_, i) => (
                                        <td key={i}>
                                            {section.namePersons?.[i] || 'لايوجد اسم'}
                                        </td>
                                    ))}
                                    {[...Array(maxPersons)].map((_, i) => (
                                        <td key={i}>
                                            {section.namePersonsFather?.[i] || 'لايوجد اسم'}
                                        </td>
                                    ))}
                                    {[...Array(maxPersons)].map((_, i) => (
                                        <td key={i}>
                                            {section.namePersonsMother?.[i] || 'لايوجد اسم'}
                                        </td>
                                    ))}
                                    {[...Array(maxCompanys)].map((_, i) => (
                                        <td key={i}>
                                            {section.nameCompanys?.[i] || 'لايوجد اسم'}
                                        </td>
                                    ))}
                                    <td>{section.part}</td>
                                    <td>{formatDate(section.date)}</td>
                                    <td>{section.ministry}</td>
                                    <td>{section.nameCompanysSpecific}</td>
                                    <td>
                                        <div className="scrollable-text">{section.text}</div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination">
                        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                            السابق
                        </button>
                        <span>
                            {currentPage} / {totalPages}
                        </span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                            التالي
                        </button>
                    </div>
                </div>
            ) : (
                <p>{message || 'No sections found for this collection.'}</p>
            )}
        </div>
    );
}

export default SectionsTable;

