import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TopBar from './TopBar'; // Import TopBar component
import './LoginPage.css';


function LoginPage({ onLogin }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Start loading
        try {
            const res = await axios.post('https://node.syriaonrecord.com/login', { email, password });
            onLogin(res.data.user); // Pass the user information to the App component
            navigate('/');
        } catch (error) {
            console.error('Error logging in:', error);
            setMessage('Invalid email or password.');
        } finally {
            setIsLoading(false); // End loading
        }
    };

    return (
        
        <div>
            <TopBar /> {/* Include TopBar component */}
            <div className="login-page">
            <div className="login-container">
                <h1>Login</h1>
                <form onSubmit={handleLogin}>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={isLoading} // Disable input during loading
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        disabled={isLoading} // Disable input during loading
                    />
                    <button type="submit" disabled={isLoading}>
                        {isLoading ? 'Logging in...' : 'Login'} {/* Button label */}
                    </button>
                </form>
                <p>{message}</p>
            </div>
            </div>
        </div>
    );
}

export default LoginPage;
