// Footer.js
import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <div className="footer">
            <ul className="footer-links">
                <li><a href="/about">About us</a></li>
                <li><a href="/contact">Contact us</a></li>
                <li><a href="/advertise">Advertise with The SyriaOnRecord</a></li>
                <li><a href="/terms">Terms & conditions</a></li>
                <li><a href="/terms">Copyright © 2024</a></li>
            </ul>
            
        </div>
    );
}

export default Footer;
