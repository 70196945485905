// AnimatedBanner.js
import React from 'react';
import './AnimatedBanner.css';

function AnimatedBanner() {
    return (
        <div className="animated-banner">
            <div className="banner-content">
                <h1>Welcome to Our Platform</h1>
                <p>Your success starts here!</p>
            </div>
        </div>
    );
}

export default AnimatedBanner;
