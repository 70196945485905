import React from 'react';
import './TopBar.css';

function TopBar({ user }) {
    return (
        <div className="top-bar">
            <div className="top-bar-logo">
                <img src="./logo512.png" alt="Logo" className="logo" />
            </div>
            <div className="top-bar-title">
                Syria<span className="red-o">O</span>nRecord
            </div>
            {user && <div className="top-bar-user">Welcome, {user.name}</div>}
        </div>
    );
}

export default TopBar;

